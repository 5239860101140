.field .ant-form-item-row {
    flex-flow: column;
  }
  
  .field .ant-form-item-label {
    padding-bottom: 0.1rem;
    text-align: left;
  }
  
  .field .ant-form-item-label label {
    height: initial;
    text-transform: uppercase;
    color: var(--grey-800);
    font-size: 0.9em;
    white-space: normal;
  }
  
  .field .ant-form-item-label label code {
    text-transform: initial;
  }
  
  .field__icon {
    flex: none;
    margin-right: 16px;
  }
  