:root {
  --primary: #387a98;
  --secondary-text: #fff;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  /* background: rgba(255, 255, 255, 0.3); */
  /* width: 100%;
    max-width: 169px;
    height: 24px; */
  /* height: 2rem; */
  /* height: 24px;  */
  /* object-fit: cover; */
}

/* .header {
    min-height: 100px;
    background-image: linear-gradient(to right, #00344d, #BCCF00, #00344d) !important;
  }
   */
.title {
  float: right;
  font-weight: 900;
  color: white;
}

.logo img {
  max-height: 4rem;
  padding: 10px;
}

.ant-menu-item-selected {
  background-color: var(--primary) !important;
  color: white !important;
  font-weight: bold !important;
}

.primary-btn {
  background-color: var(--primary);
}

.bg-primary {
  background-color: var(--primary);
  box-shadow: 2px 2px 2px var(--primary);
}
.text-primary{
  color:var(--primary)
}

 a{
  text-decoration: none}
  ;
.text-secondary {
  color: var(--secondary-text);
}

.wdth-120 {
  width: 150px;
  ;
}

.text-bold {
  font-weight: 600;
}

.transparent-background {
  background-color: white;
  box-shadow: 0 3.2px 7.2px 0 var(rgba(0, 0, 0, .132), rgba(0, 0, 0, .132)), 0 .6px 1.8px 0 rgba(0, 0, 0, .108);
}

.danger-text {
  color: red;
  font-weight: 600;
}

.primary-color {
  color: var(--primary);
}

.anticon-inbox{
    color: var(--primary)!important
  }
  .anticon-lock{
    color: var(--primary)!important;
  }

  .anticon-left
{
  color: var(--primary);
}

.ant-result-subtitle {
  color: var(--primary) !important;
  font-weight: bold;
}

.button-primary {
  background-color: var(--primary) !important;
}
.ant-btn-primary{
  background-color: var(--primary) !important;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.flex-container-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
}

.site-header__container {
  padding: 0px 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: linear-gradient(to right, #185169, #469170, #185169) !important;
}

.site-header {
  height: 3rem;
  width: 100%;
}


.Header__logo {
  height: 2rem;
  object-fit: cover;
  padding: 2px;
}

.ant-menu.nav-menu {
  background-color: transparent;
  border-bottom: none;
  color: white;
  display: flex;
  align-items: center;
}

.ant-menu.nav-menu .ant-menu-item,
.ant-menu.nav-menu .ant-menu-submenu {
  border-bottom: none;
  color: white;
  line-height: normal;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 10px;
}

.ant-menu.nav-menu .ant-menu-item a {
  text-decoration: none;
  color: white;
}

.ant-menu.nav-menu .ant-menu-item:hover,
.ant-menu.nav-menu .ant-menu-submenu:hover,
.ant-menu.nav-menu .ant-menu-submenu-active {
  border-bottom: none;
  color: white;
  border: 1px solid white;
}

.ant-menu.nav-menu .ant-menu-submenu-title:hover {
  color: white;
}

.ant-menu.nav-menu .ant-menu-item-selected {
  background-color: white;
  color: var(--primary);
}

.ant-menu.nav-menu .ant-menu-item::after,
.ant-menu.nav-menu .ant-menu-item:hover::after {
  border-bottom: none;
}

.ant-menu.nav-menu .ant-menu-item-selected a {
  color: white;
}

.ant-menu.nav-menu .ant-menu-item-selected:hover {
  color: var(--primary);
}

.mb-10{
  margin-bottom: 10px;
}
.text-normal{
  color: black !important
}
.anticon-check-circle{
  color: green 
}
.anticon-close-circle{
  color: red;
}
.anticon-info-circle{
  color: var(--primary) !important;
}